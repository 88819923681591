import * as Sentry from '@sentry/vue'

// Sentry.captureExceptionでうけとる型が anyのため、anyに指定
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendAuthExceptionToSentry = (exception: any, message?: string) => {
  // In v9, the format for context data in captureException is updated
  Sentry.captureException(exception, {
    contexts: {
      tags: { type: 'auth' },
      extra: { message }
    }
  })
}

export const extractTeamIdFromURL = (url: URL) => {
  if (url.pathname === '/list/availability') {
    return url.searchParams.get('selected-segment')
  }
  // UI Revampでパス構造変わってこっちのコードで動くようになる
  return url.pathname.match(/\/teams\/([A-Za-z0-9_-]+)/)?.[1] ?? null
}

export const shouldSendManyTraces = (teamId: string) =>
  // The team names in the comments are removed at build time.
  [
    'QnK95Y2CC7H6_NDzwt1MW', // LayerX
    'amVXijVAEYn7dDfU-XmSC', // 株式会社estie
    'I6Y4qICNsHEXLWAA27eUL', // UPSIDER
    'cbOHHcA9CF0QzldAnk6r-', // 株式会社Helpfeel
    'XhiUC3uZiAWVl_r8VR-EQ', // 株式会社M&Aクラウド
    'BOV4fjgHOux5GlyhlQ2rD', // 株式会社YOUTRUST
    'rEq6TD6Xduz5miVmkZhR7', // 株式会社ユーザベース
    'Jy1DjKolql48qWaOstGha', // リクルートスタッフィング
    'uNEWryl66LJVEOwlHtnEg', // SB Institute
    'K83TPCfLgfC7O-zgXkm9M', // HENNGE
    'xJR9tIE3mtM-1Y3IBxjPV' // 株式会社Sales Marker
  ].includes(teamId)
