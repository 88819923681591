import i18n from '@/i18n'
import { getDefaultSelectedCalendar } from '@/lib/utils'
import { getCandidateSource, isEditable } from '@/lib/utils/candidate'
import { decideOnlineMeeting } from '@/lib/utils/decideOnlineMeeting'
import { Duration } from '@/models/duration'
import ScheduleLocalStorage from '@/models/localStorage/Schedule'
import CalendarsModule, { encodeCalendarKeyByAccountIdAndCalendarId } from '@/store/modules/calendars'
import ProfileModule from '@/store/modules/profile'
import TeamRecordModule from '@/store/modules/teamRecord'
import TempEventModule from '@/store/modules/tempEvent'
import UserModule from '@/store/modules/user'
import {
  Attendee,
  Candidate,
  CandidateStatus,
  DEFAULT_SLOT_DURATION,
  DefaultOnlineMeetingConnectResult,
  FullCalendarEvent,
  ICalendarListForUI,
  IOnlineMeeting,
  ListType,
  OnlineMeetingType,
  OrganizerInfo,
  SHORT_SLOT_DURATION,
  ScheduleStatus,
  ScheduleStatusUpdateAction,
  SpirUser,
  VISIBILITY
} from '@/types'
import {
  Creator,
  MemberResponse,
  TeamsTeamIdSchedulesPostRequest,
  TeamsTeamIdSchedulesScheduleIdGetResponse
} from '@spirinc/contracts'
import { isBefore, parseISO } from 'date-fns'
import { cloneDeep, pick, sortBy } from 'lodash'
import { nanoid } from 'nanoid'
import {
  CommonCandidatesControls,
  MAX_CANDIDATE_COUNT as MAX_CANDIDATE_NUM,
  TeamCandidatesControls
} from './../../components/ui/domain/sections/arrangement/composables/logics/candidates'
import { TeamDetailModel } from './team'

export const MAX_CANDIDATE_COUNT = MAX_CANDIDATE_NUM

type Confirmer = {
  email: string
  spirUserId?: string
}

export type ScheduleDuration = Duration
// fixme: 現在サーバーからのResponseと差がある。
export type ScheduleCommonAttrs = {
  id?: string
  title: string
  duration: ScheduleDuration
  location?: string
  description?: string
  visibility: VISIBILITY
  start?: string
  end?: string
  processed?: boolean
  author?: SpirUser
  candidates: Array<Candidate> //todo: make candidate model next time
  confirmer?: Confirmer
  confirmedInfo?: {
    confirmedUser?: {
      name: string
    }
    eventId: string
    start: string
    end: string
  }
  createdAt?: string // todo: createAtが機能していない
  updatedAt?: string
  deleted?: boolean
  actions?: ScheduleStatusUpdateAction[]
  status?: ScheduleStatus
  fetchCalendarService?: boolean
  attendees?: Attendee[]
}
export type PrivateScheduleAttr = ScheduleCommonAttrs & {
  accountId: string
  calendarId: string
  onlineMeeting: IOnlineMeeting
  attendees?: Attendee[]
}

export interface ListClass {
  isConfirmed: boolean
  urlForConfirmer: string
  name: string
  calendarKey: string
}

export abstract class ScheduleModelCommon {
  id?: string
  title: string
  duration: ScheduleDuration
  location?: string
  description?: string
  visibility: VISIBILITY
  start?: string
  end?: string
  createdAt?: string
  updatedAt?: string
  candidates: Array<Candidate> //todo: make candidate model next time
  confirmedInfo?: {
    confirmedUser?: {
      name: string
    }
    eventId: string
    start: string
    end: string
  }
  confirmer?: Confirmer
  deleted?: boolean
  actions?: ScheduleStatusUpdateAction[]
  status?: ScheduleStatus
  fetchCalendarService?: boolean
  name = 'schedules'
  // @ts-expect-error TS2564
  onlineMeeting: IOnlineMeeting
  MAX_CANDIDATE_COUNT = MAX_CANDIDATE_COUNT
  type: ListType = ListType.SCHEDULE
  attendees?: Attendee[]
  constructor(data: ScheduleCommonAttrs) {
    this.id = data.id
    this.title = data.title
    this.duration = data.duration
    this.location = data.location
    this.description = data.description
    this.visibility = data.visibility
    this.start = data.start
    this.end = data.end
    this.candidates = []
    if (data.candidates && data.candidates.length > 0) {
      this.candidates = sortBy(data.candidates, 'start')
    }
    this.status = data.status
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.deleted = data.deleted
    this.actions = data.actions
    this.fetchCalendarService = data.fetchCalendarService
    this.confirmer = data.confirmer
    this.attendees = data.attendees || []
    const confirmedCandidate = data.candidates.find(c => c.status === 'confirmed')
    if (confirmedCandidate) {
      // @ts-expect-error TS2322
      this.confirmedInfo = pick(confirmedCandidate, ['start', 'end', 'eventId'])
    }
  }
  get canDisplayDate() {
    // fixme: 有効な候補があるかどうか存在チェックが必要
    return (
      this.status === 'suggestedByOrganizer' ||
      this.status === 'suggestedByConfirmer' ||
      this.status === 'requestedByConfirmer'
    )
  }
  get validCandidates() {
    return this.candidates.filter(c => {
      return (
        c.status !== CandidateStatus.deleted &&
        c.status !== CandidateStatus.rejected &&
        c.status !== CandidateStatus.expiredPoll &&
        c.status !== CandidateStatus.expired
      )
    })
  }
  get isInvalid(): boolean {
    return this.status === 'deleted' || this.status === 'cancelled'
  }
  get isConfirmed(): boolean {
    if (!this.candidates || this.candidates.length === 0) {
      return false
    }
    return this.status === 'confirmed'
  }

  get firstCandidateStartDate(): Date | null {
    return this.candidates && this.candidates.length > 0 ? parseISO(this.candidates[0].start) : null
  }
  // カレンダー表示対象。表示はRejectedも表示する
  get getCandidatesToDisplayOnCalendar() {
    return CommonCandidatesControls.getCandidatesToDisplayOnCalendar({ candidates: this.candidates })
  }
  get getCandidatesForConfirm(): FullCalendarEvent[] {
    return this.getEditingEventByCalendarFormat
      .filter(event => {
        if (isBefore(event.end, new Date())) {
          return false
        }
        // @ts-expect-error TS2532
        switch (event.extendedProps.source) {
          case 'expired':
          case 'rejected':
          case 'declined':
            return false
        }
        // @ts-expect-error TS2532
        return !event.extendedProps.underDuration
      })
      .map(event => ({ ...event, extendedProps: { ...event.extendedProps, source: 'pending' } }))
  }
  getPreviewEventByCalendarFormat(): FullCalendarEvent[] {
    return this.getEditingEventByCalendarFormat.map(c => {
      return {
        ...c,
        editable: false,
        title: this.status === 'confirmed' ? this.title : `${i18n.t('labels.candidate').toString()}`
      }
    })
  }
  get activeCandidates(): Candidate[] {
    return CommonCandidatesControls.getActiveCandidates(this.candidates)
  }
  get candidatesForPayload() {
    return this.candidates.map(c => {
      const clone = { ...c }
      if (clone.addedDate && clone.id) {
        delete clone.id
      }
      return clone
    })
  }
  removeCandidateIfitsUnderNewDuration(): boolean {
    const { remained, removed } = CommonCandidatesControls.separateUnderNewDurationCandidatesOrNot({
      candidates: this.candidates,
      duration: this.duration
    })
    this.candidates = remained
    return removed.length === 0
  }
  addCandidate(start: Date, end: Date, candidateId?: string) {
    const newCandidates = CommonCandidatesControls.addCandidate({
      candidates: this.candidates,
      duration: this.duration,
      payload: {
        start,
        end,
        candidateId
      }
    })
    this.candidates = newCandidates
  }
  removeCandidate(candidateId: string, now = new Date()): boolean {
    const newCandidates = CommonCandidatesControls.removeCandidate({ candidates: this.candidates, candidateId, now })
    const isRemoved = newCandidates.length !== this.candidates.length
    this.candidates = newCandidates
    return isRemoved
  }
  get startTimeIncrements() {
    return this.duration < DEFAULT_SLOT_DURATION ? SHORT_SLOT_DURATION : DEFAULT_SLOT_DURATION
  }
  abstract get payload()
  abstract get urlForConfirmer(): string
  abstract get getEditingEventByCalendarFormat(): FullCalendarEvent[]
  abstract get availableOnlineMeetings(): OnlineMeetingType[]
  abstract get areAllEventsSynced(): boolean
  abstract get amIOrganizer(): boolean
  abstract get organizerInfo(): OrganizerInfo
}
/**
 * 優先順位
 * 1. カレンダーからドラックされた時のAccountIdとCalendarID
 * 2. 前回選択して且つ有効なID
 * 3. プライマリーカレンダー
 * @param savedCalendar
 * @returns
 */
const defaultSelectedCalendar = (savedCalendar?: { accountId: string; calendarId: string }) => {
  const tempEvent = TempEventModule.getTempEvent
  if (tempEvent && tempEvent.extendedProps?.calendarId) {
    return {
      accountId: tempEvent.extendedProps.accountId,
      calendarId: tempEvent.extendedProps.calendarId
    }
  } else {
    const defaultCalendar = getDefaultSelectedCalendar(CalendarsModule.primaryCalendars, savedCalendar)
    return {
      accountId: defaultCalendar.accountId,
      calendarId: defaultCalendar.calendarId
    }
  }
}
// private
export class ScheduleModel extends ScheduleModelCommon implements ListClass {
  accountId: string
  calendarId: string
  calendarKey: string
  // @ts-expect-error TS2564
  type: ListType
  processed: boolean
  author?: SpirUser
  // @ts-expect-error TS2564
  defaultOnlineMeetingSettingResult: DefaultOnlineMeetingConnectResult
  constructor(data?: PrivateScheduleAttr) {
    if (!data) {
      const localStorageSaver = new ScheduleLocalStorage()
      const savedValues: {
        accountId?: string
        calendarId?: string
        duration?: ScheduleDuration
        visibility?: VISIBILITY
        onlineMeeting?: IOnlineMeeting
      } = localStorageSaver.loadFromLocalStorage()

      const defaultValues = {
        title: '',
        location: '',
        candidates: [],
        description: '',
        attendees: [],
        duration: savedValues.duration || 60,
        visibility: savedValues.visibility || VISIBILITY.DEFAULT
      }
      super(defaultValues)
      const { accountId, calendarId } = defaultSelectedCalendar(
        savedValues.accountId && savedValues.calendarId
          ? {
              accountId: savedValues.accountId,
              calendarId: savedValues.calendarId
            }
          : undefined
      )
      // @ts-expect-error TS2322
      this.accountId = accountId
      this.calendarId = calendarId
      const onlineMeetingToolResult = decideOnlineMeeting(
        // @ts-expect-error TS2345
        CalendarsModule.getCalendar({
          // @ts-expect-error TS2322
          accountId,
          calendarId
        }),
        UserModule.isConnectedZoom,
        savedValues.onlineMeeting
      )
      this.onlineMeeting = onlineMeetingToolResult.onlineMeeting
      this.defaultOnlineMeetingSettingResult = onlineMeetingToolResult.result
      this.processed = false
    } else {
      super(data)
      this.accountId = data.accountId
      this.calendarId = data.calendarId
      this.onlineMeeting = data.onlineMeeting
      // @ts-expect-error TS2322
      this.processed = data.processed
      this.author = data.author
    }
    const calendarKey = encodeCalendarKeyByAccountIdAndCalendarId(this.accountId, this.calendarId)
    this.calendarKey = calendarKey
  }
  get areAllEventsSynced() {
    return this.processed
  }
  get payload() {
    return {
      title: this.title,
      duration: this.duration,
      accountId: this.accountId,
      calendarId: this.calendarId,
      location: this.location,
      description: this.description,
      visibility: this.visibility,
      onlineMeeting: this.onlineMeeting,
      attendees: this.attendees,
      candidates: this.candidates
    }
  }
  get urlForConfirmer(): string {
    return `${window.location.origin}/${this.name}/${this.id}`
  }
  get calendarInfo() {
    const allCalendars: ICalendarListForUI = CalendarsModule.flattenCalendars
    if (!allCalendars[this.calendarKey]) {
      return null
    }
    return {
      backgroundColor: allCalendars[this.calendarKey].backgroundColor,
      foregroundColor: allCalendars[this.calendarKey].foregroundColor,
      calendarName: allCalendars[this.calendarKey].title
    }
  }
  get getEditingEventByCalendarFormat(): FullCalendarEvent[] {
    const accountId = this.accountId
    const calendarId = this.calendarId
    // @ts-expect-error TS2322
    return this.getCandidatesToDisplayOnCalendar.map((c: Candidate) => {
      const title = ((): string => {
        if (this.status === ScheduleStatus.confirmed) {
          return this.title
        }
        if (c.status === CandidateStatus.rejected) {
          return i18n.t('labels.rejected').toString()
        }
        return i18n.t('labels.candidate').toString()
      })()
      return {
        title: title,
        id: c.id,
        start: parseISO(c.start),
        end: parseISO(c.end),
        extendedProps: {
          source: getCandidateSource(c.status),
          accountId,
          calendarId,
          canConfirm: !c.addedDate && !c.updateDate, // 確定可能な候補は編集されていない候補
          underDuration: c.underDuration,
          status: c.status
        },
        editable: isEditable(c.status)
      }
    })
  }
  get availableOnlineMeetings() {
    const currentCalendar = CalendarsModule.getCalendar({
      accountId: this.accountId,
      calendarId: this.calendarId
    })
    // @ts-expect-error TS2532
    return currentCalendar.availableOnlineMeetings || []
  }
  get amIOrganizer() {
    return this.author?.id === ProfileModule.myProfile.id
  }
  get organizerInfo(): OrganizerInfo {
    return {
      // @ts-expect-error TS2532
      fullName: this.author.name || this.author.fullName,
      // @ts-expect-error TS2532
      userId: this.author.id,
      // @ts-expect-error TS2532
      photoURL: this.author.photoURL
    }
  }
}
export class ScheduleModelTeam extends ScheduleModelCommon {
  organizerMemberId: string
  attendingMembers: { memberId: string }[]
  teamId: string
  creator: Creator
  lastUpdateUser?: {
    userId: string
    fullName: string
  }
  allEventsSynced: boolean
  notifications: { email: string }[]
  organizer: { fullName?: string; userId?: string; photoURL?: string }
  constructor(teamId: string, data?: TeamsTeamIdSchedulesScheduleIdGetResponse) {
    // @ts-expect-error TS2322
    const attendees: Attendee[] =
      // @ts-expect-error TS2532
      data?.attendees?.length > 0
        ? data?.attendees
            .filter(a => !a.isOrganizer)
            .map(a => {
              return {
                fullName: a.fullName,
                id: a.userId,
                photoURL: a.photoURL,
                organizer: false
              }
            })
        : []
    super({
      ...data,
      id: data?.id,
      title: data?.title || '',
      duration: data?.duration || 60, // todo: get default value from local storage,
      location: data?.location,
      description: data?.description,
      visibility: (data?.visibility as VISIBILITY) || VISIBILITY.DEFAULT,
      candidates: data?.candidates || [],
      attendees: attendees
    })
    this.teamId = teamId
    // @ts-expect-error TS2531
    this.organizerMemberId = data?.organizerMemberId || TeamRecordModule.teamByTeamId(teamId).myInfo.id
    this.attendingMembers = data?.attendingMembers || []
    this.onlineMeeting = data?.onlineMeeting || { type: 'none' }
    this.lastUpdateUser = data?.lastUpdateUser
    // @ts-expect-error TS2322
    this.creator = data?.creator
    this.allEventsSynced = data?.allEventsSynced || false
    if (data?.status === 'confirmed' && data?.candidates?.length > 0) {
      // @ts-expect-error TS2741
      this.confirmedInfo = pick(data.candidates[0], ['start', 'end', 'eventId'])
    }
    this.notifications = data?.notifications || []
    // @ts-expect-error TS2322
    this.organizer = data?.attendees?.find(a => a.isOrganizer)
  }
  get payload(): TeamsTeamIdSchedulesPostRequest {
    return {
      organizerMemberId: this.organizerMemberId,
      title: this.title,
      description: this.description,
      duration: this.duration,
      location: this.location,
      visibility: this.visibility,
      candidates: this.candidatesForPayload,
      attendingMembers: this.attendingMembers,
      onlineMeeting: this.onlineMeeting,
      notifications: this.notifications
    }
  }
  get teamInfo(): TeamDetailModel {
    // @ts-expect-error TS2322
    return TeamRecordModule.teamByTeamId(this.teamId)?.team
  }
  get allTeamMembers(): MemberResponse[] {
    return this.teamInfo?.members || []
  }
  get allActiveTeamMembers(): MemberResponse[] {
    return this.allTeamMembers.filter(m => m.status === 'active')
  }
  get allAttendingMembers(): MemberResponse[] {
    return this.attendingMembers
      .map(member => {
        const memberInfo = this.allActiveTeamMembers.find(am => am.id === member.memberId)
        return memberInfo
      })
      .filter(m => !!m)
  }
  get isValidForConfirm() {
    return this.title && this.organizerMemberId
  }
  get urlForConfirmer(): string {
    return `${window.location.origin}/t/${this.teamId}/s/${this.id}/c`
  }
  get organizerWithMemberInfo() {
    return this.allTeamMembers.find(m => m.id === this.organizerMemberId)
  }
  get availableOnlineMeetings() {
    // @ts-expect-error TS2532
    return this.organizerWithMemberInfo.availableOnlineMeetings
  }
  get organizerInfo(): OrganizerInfo {
    if (this.organizer) {
      return this.organizer
    }
    // @ts-expect-error TS2322
    return TeamRecordModule.teamByTeamId(this.teamId).team.members.find(m => m.id === this.organizerMemberId)
  }
  get amIOrganizer(): boolean {
    return this.organizerMemberId === TeamRecordModule.teamByTeamId(this.teamId)?.myInfo?.id
  }
  get getEditingEventByCalendarFormat(): FullCalendarEvent[] {
    const fullCalendarEvents = TeamCandidatesControls.getEditingEventByCalendarFormat({
      candidates: this.candidates,
      title: this.title,
      // @ts-expect-error TS2322
      status: this.status
    })
    return fullCalendarEvents
  }
  get areAllEventsSynced() {
    return this.allEventsSynced
  }
  addCandidate(start: Date, end: Date, candidateId?: string) {
    const newCandidates = TeamCandidatesControls.addCandidate({
      candidates: this.candidates,
      duration: this.duration,
      payload: {
        start,
        end,
        candidateId
      }
    })
    this.candidates = newCandidates
  }
  replaceAllVaildCandidates(newCandidates: { start: string; end: string }[]): 'overMaxCandidate' | null {
    const remingCandidates = this.candidates.filter(
      c => c.isExpired || c.status === CandidateStatus.rejected || c.status === CandidateStatus.expired
    )
    this.candidates = remingCandidates.concat(
      newCandidates.slice(0, this.MAX_CANDIDATE_COUNT).map(c => ({ ...c, id: nanoid(), addedDate: new Date() }))
    )
    return newCandidates.length > this.MAX_CANDIDATE_COUNT ? 'overMaxCandidate' : null
  }
  removeCandidateById(id) {
    const newCandidates = TeamCandidatesControls.removeCandidateById({ candidateId: id, candidates: this.candidates })
    this.candidates = newCandidates
  }
  udpateOrganizer(newValue) {
    if (this.allActiveTeamMembers.some(am => am.id === newValue)) {
      this.organizerMemberId = newValue
    }
  }
  updateByTeamScheduleFormData(data: Partial<ScheduleModelTeam>): ScheduleModelTeam {
    const old = cloneDeep(this)
    old.udpateOrganizer(data.organizerMemberId)
    // @ts-expect-error TS2322
    old.title = data.title
    // @ts-expect-error TS2322
    old.duration = data.duration
    old.location = data.location
    old.description = data.description
    // @ts-expect-error TS2322
    old.visibility = data.visibility
    // @ts-expect-error TS2322
    old.onlineMeeting = data.onlineMeeting
    // @ts-expect-error TS2322
    old.candidates = data.candidates
    // @ts-expect-error TS2322
    old.attendingMembers = data.attendingMembers
    old.attendees = data.attendees
    // @ts-expect-error TS2322
    old.notifications = data.notifications
    return old
  }
  /**
   * LocalStorageから値を戻す時に使用。
   * fixme: 現在LocalStorageから取得した値に型がないがサービス全般でLocalStorageに保存、復元で型を使えるようにしたい。
   * 戻す時、順番も気にしないといけない。オンラインミーティングは主催者によってきまるので、主催者を設定してからオンラインミーティングを設定すべき。
   * その他の項目は関係ない
   * @param savedValus
   */
  setValues(savedValus: { [key: string]: any }) {
    if (savedValus['organizerMemberId']) {
      this.udpateOrganizer(savedValus['organizerMemberId'])
    }
    // 保存されてるオンラインミーティングが利用可能な場合のみセット
    if (savedValus['onlineMeeting']?.type) {
      const newOnlineType = savedValus['onlineMeeting'].type
      if (this.availableOnlineMeetings?.some(am => am === newOnlineType)) {
        this.onlineMeeting.type = newOnlineType
      }
    }
    if (savedValus['duration']) {
      this.duration = Number(savedValus['duration']) as ScheduleDuration
    }
    if (savedValus['visibility']) {
      this.visibility = savedValus['visibility'] as VISIBILITY
    }
  }
}
