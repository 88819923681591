import { AvailabilityModelForTeam } from '@/models/data'
import store from '@/store'
import { cloneDeep } from 'lodash'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'

const MODULE_NAME = 'EditAvailabilityTeam'

// const savedAvailability = new AvailabilityStorage()
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class EditAvailabilityTeam extends VuexModule {
  _editModel: AvailabilityModelForTeam | null = null

  get getModel(): AvailabilityModelForTeam {
    // @ts-expect-error TS2322
    return this._editModel
  }
  get showMemberCalendars(): string[] | undefined {
    return this.getModel?.showMemberCalendars
  }
  @Action
  reset() {
    this.SET_MODEL(null)
  }
  @Mutation
  SET_MODEL(model: AvailabilityModelForTeam | null) {
    this._editModel = cloneDeep(model)
  }
}

export default getModule(EditAvailabilityTeam)
