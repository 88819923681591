<template>
  <div :id="mountId" />
</template>

<script lang="ts">
import { render } from './render-function'
import { defineComponent, onMounted, type PropType } from '@vue/composition-api'
import { nanoid } from 'nanoid'
import { useLanguageSetting } from '@/composables/useLanguageSetting'
import React from 'react'

export default defineComponent({
  name: 'ReactPagesRenderer',
  props: {
    Component: {
      type: Function as PropType<React.FC>,
      required: true
    }
  },
  setup(props) {
    const mountId = nanoid()
    const { currentLanguage } = useLanguageSetting()
    const { Component } = props
    onMounted(() => {
      render({ mountId, Component, locale: currentLanguage.value })
    })
    return {
      mountId
    }
  }
})
</script>
