export const SubscriptionStatus = {
  active: 'active',
  canceled: 'canceled',
  trialing: 'trialing',
  canceling: 'canceling',
  pastDue: 'pastDue',
  trialWithoutSetup: 'trialWithoutSetup',
  incomplete: 'incomplete'
} as const
export type SubscriptionStatus = keyof typeof SubscriptionStatus

export const CancelReasons = [
  'userCancel',
  'paymentFailed',
  'trialExpired',
  'unknownReason',
  'incompleteExpired'
] as const
export type CancelReason = (typeof CancelReasons)[number]
