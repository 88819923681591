import i18nGlobal from '@/i18n'
import { getDefaultSelectedCalendar } from '@/lib/utils'
import { weekDays } from '@/lib/utils/calendars'
import { FromHolidays } from '@/lib/utils/converters/fromHolidays'
import {
  convertToAssignPriorityGroups,
  convertToTeamAvailabilitySharingPriorityGroups
} from '@/lib/utils/converters/fromPriorityGroups'

import {
  EndSelects,
  PickDayOfWeekDefaultEvent,
  StartSelects,
  defaultWeekRule,
  endSelects,
  parseFromTime
} from '@/lib/utils/peekCandidates'
import {
  addDays,
  addMonths,
  addWeeks,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfWeekByUserStartWeekday
} from '@/lib/utils/timezone'
import { Duration } from '@/models/duration'
import AvailabilityStorage from '@/models/localStorage/Availability'
import { AllRouteNames } from '@/router'
import { getDefaultOnlineMeetingType } from '@/store/lib/utils'
import CalendarControl from '@/store/modules/calendarControl'
import { default as CalendarModule, default as CalendarsModule } from '@/store/modules/calendars'
import TeamRecordModule from '@/store/modules/teamRecord'
import TimezoneModule from '@/store/modules/timezones'
import {
  FullCalendarEvent,
  Holiday,
  ICalendar,
  IOnlineMeeting,
  MAX_NUMBER_OF_COUNTRIES_FOR_HOLIDAY_EXCLUSION,
  ResourceEvent,
  WorkDayKey
} from '@/types'
import { FrontSupportCountryCode } from '@/types/frontSupportCountry'
import {
  AvailabilityCalendarType,
  AvailabilitySharingsAvailabilitySharingIdGetResponse,
  AvailabilitySharingsPeekCandidatesPostRequest,
  Countries,
  Country,
  DayOfTheWeekRules,
  ExceptionRules,
  MemberResponse,
  PrioritizedOnlineMeetings,
  TeamAvailabilitySharingListResponseItem,
  TeamAvailabilitySharingPriorityGroups,
  TeamRoomPriorityGroups,
  TeamTeamIdAvailabilitySharingsPostRequest,
  TeamTeamIdAvailabilitySharingsPostResponse,
  TeamsTeamIdAvailabilitySharingsAvailabilitySharingIdPatchRequest,
  TeamsTeamIdAvailabilitySharingsPreviewSlotsPostRequest
} from '@spirinc/contracts'
import { addHours, differenceInHours, isBefore, isEqual } from 'date-fns'
import deepEqual from 'deep-equal'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import { IVueI18n } from 'vue-i18n'
import { Location } from 'vue-router'
import { AssignPriorityGroups } from '../attendee'
import { NotificationSlackChannel } from '../integrations'
import { QuestionnaireId } from '../questionnaire'
import type { TitleForIndividualEvents } from '../teamAvailabilitySharing'
import { TeamDetailModel } from './team'

const UpdateResult = {
  onlineMtgUpdated: 'onlineMtgUpdated',
  meetingRoomsRefreshed: 'meetingRoomsRefreshed'
} as const
export type UpdateResult = keyof typeof UpdateResult

export type DisplayWeekDay = {
  key: WorkDayKey
  check: boolean
  timesEvents: ResourceEvent[]
}

export type Rule = {
  start: string
  end: string
  type: 'include' | 'exclude'
}
export type Exception = Rule & {
  id: string
  allDay?: boolean
}

const MAX_NUM_OF_DAY = 50

const decodeException = (exceptions: Rule[]): Exception[] => {
  return exceptions.map(e => {
    const startDate = new Date(e.start)
    const endDate = new Date(e.end)
    const isAllDay = () => {
      const startOfStart = startOfDay(startDate)
      if (!isEqual(startOfStart, startDate)) {
        return false
      }
      const diffEndAndStart = differenceInHours(endDate, startDate)
      if (diffEndAndStart < 24) {
        return false
      }
      return diffEndAndStart % 24 === 0
    }
    return {
      ...e,
      id: nanoid(),
      allDay: isAllDay()
    }
  })
}

export const convertToDisplayWeekDay = (dayOfTheWeekRules: DayOfTheWeekRules): DisplayWeekDay[] => {
  const startDay = startOfWeekByUserStartWeekday(new Date())
  return weekDays(CalendarControl.startWeek).map((weekday, i) => {
    const currentDate = addDays(startOfDay(startDay), i)
    return {
      key: weekday,
      // @ts-expect-error TS2322
      check: dayOfTheWeekRules[weekday]?.rules?.length > 0,
      timesEvents:
        dayOfTheWeekRules[weekday]?.rules?.map(rule => {
          return PickDayOfWeekDefaultEvent(
            parseFromTime(rule.start, currentDate),
            parseFromTime(rule.end, currentDate),
            weekday
          )
        }) || []
    }
  })
}

export const AttendeeType = {
  and: 'and',
  or: 'or'
} as const

export type AttendeeType = (typeof AttendeeType)[keyof typeof AttendeeType]

export type AvailabilityAttendee = {
  accountId: string
  calendarId: string
  title?: string
  email?: string
}

/**
 * @deprecated
 * use src/models/confirmationForm.ts/ConfirmationFormProperty
 * Confirmation form
 */
export type ConfirmationFormProperty = {
  type: 'string'
  description?: string
  tooltip?: string
  widget: 'textarea' | 'input'
  icon?: string
  isActive?: boolean // 本来ならObjectから消せばよいが、現在の仕様が表示/非表示の切り替えが必要なので追加
}
/**
 * @deprecated
 * use src/models/confirmationForm.ts/ConfirmationForm
 */
export type ConfirmationForm = {
  type: 'object'
  description: string
  properties: { [key: string]: ConfirmationFormProperty }
  required: Array<string>
  'x-spir-properties-order': Array<string>
}

// DBのマイグレーションの前、生成時に追加
export const initConfirmationForm = (
  options: { isActive: boolean } = { isActive: true },
  i18n: IVueI18n = i18nGlobal
): ConfirmationForm => {
  return {
    type: 'object',
    description: i18n.t('availabilitySharing.confirmationForm.description').toString(),
    properties: {
      message: {
        type: 'string',
        description: i18n.t('availabilitySharing.confirmationForm.message.description').toString(),
        tooltip: i18n.t('availabilitySharing.confirmationForm.message.tooltip').toString(),
        widget: 'textarea',
        icon: 'message',
        isActive: options.isActive
      }
    },
    required: [],
    'x-spir-properties-order': ['message']
  }
}

export const timeBuffers = [0, 15, 30, 45, 60, 90, 120] as const
export type TimeBuffer = (typeof timeBuffers)[number]

export abstract class AvailabilityModel {
  // @ts-expect-error TS2564
  type: 'private' | 'team'
  id?: string
  title: string
  duration: Duration
  location?: string
  visibility: 'default' | 'public' | 'private' | 'confidential'
  description?: string
  candidateTitle: string
  candidateDescription?: string
  timeBuffer: TimeBuffer
  isPublished: boolean
  start: StartSelects | string
  end: EndSelects | string
  dayOfTheWeekRules: DayOfTheWeekRules
  exceptions: Exception[]
  countries?: Countries
  holidays?: Holiday[]
  createdAt?: Date
  index?: number
  timeZone: string
  excludeScheduleCandidates: boolean
  excludeGroupPollCandidates: boolean
  excludeAllDays: boolean
  isLoading: boolean
  updatedAt?: Date
  confirmationForm?: ConfirmationForm
  constructor(data?: {
    id: string
    title: string
    description?: string
    candidateTitle: string
    candidateDescription?: string
    location?: string
    duration: Duration
    start:
      | string
      | 'now'
      | 'one_hour_later'
      | 'two_hours_later'
      | 'three_hours_later'
      | 'four_hours_later'
      | 'five_hours_later'
      | 'tomorrow'
      | 'the_day_after_tomorrow'
      | 'three_days_later'
      | 'four_days_later'
      | 'five_days_later'
      | 'next_week'
      | 'one_week_later'
      | 'the_week_after_next'
      | 'two_weeks_later'
      | 'three_weeks_later'
      | 'four_weeks_later'
      | 'next_month'
    end:
      | string
      | 'one_day'
      | 'two_days'
      | 'three_days'
      | 'four_days'
      | 'five_days'
      | 'one_week'
      | 'two_weeks'
      | 'three_weeks'
      | 'four_weeks'
      | 'two_months'
      | 'three_months'
    dayOfTheWeekRules: DayOfTheWeekRules
    exceptions: ExceptionRules
    timeBuffer: 0 | 15 | 30 | 45 | 60 | 90 | 120 | number // fixme: contractの反映が必要
    excludeScheduleCandidates: boolean
    excludeGroupPollCandidates: boolean
    excludeAllDays: boolean
    visibility: 'default' | 'public' | 'private' | 'confidential'
    isPublished: boolean
    countries?: Countries
    holidays?: Holiday[]
    createdAt: string
    index?: number
    timeZone: string
    updatedAt: string
    confirmationForm?: ConfirmationForm
  }) {
    if (!data) {
      //新規作成
      this.title = ''
      this.candidateTitle = ''
      this.description = ''
      this.candidateDescription = ''
      this.candidateTitle = ''
      this.timeBuffer = 0
      this.duration = 60
      this.isPublished = false
      this.start = 'tomorrow'
      this.end = 'two_weeks'
      this.dayOfTheWeekRules = defaultWeekRule()
      this.exceptions = []
      this.countries = []
      this.holidays = []
      this.timeZone = TimezoneModule.userTimezoneKey
      this.visibility = 'default'
      this.excludeGroupPollCandidates = false
      this.excludeScheduleCandidates = false
      this.excludeAllDays = true
      this.confirmationForm = initConfirmationForm()
    } else {
      this.id = data.id
      this.title = data.title
      this.duration = data.duration
      this.location = data.location
      this.description = data.description
      this.visibility = data.visibility
      this.candidateDescription = data.candidateDescription || ''
      this.candidateTitle = data.candidateTitle || ''
      this.timeBuffer = data.timeBuffer as 0 | 15 | 30 | 45 | 60 | 90 | 120
      this.isPublished = data.isPublished
      this.start = data.start
      if (endSelects.indexOf(data.end as EndSelects) >= 0) {
        this.end = data.end as EndSelects
      } else {
        this.end = data.end
      }
      this.dayOfTheWeekRules = data.dayOfTheWeekRules
      this.exceptions = data.exceptions ? decodeException(data.exceptions as Rule[]) : []
      this.countries = data.countries ? data.countries.slice(0, MAX_NUMBER_OF_COUNTRIES_FOR_HOLIDAY_EXCLUSION) : []
      this.holidays = data.holidays ? data.holidays : []
      this.createdAt = data.createdAt ? new Date(data.createdAt) : undefined
      // @ts-expect-error TS2532
      this.index = data.index >= 0 ? data.index : Number.MAX_SAFE_INTEGER // if index is minus, set maximum number
      this.timeZone = data.timeZone
      this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : undefined
      this.excludeGroupPollCandidates = data.excludeGroupPollCandidates || false
      this.excludeScheduleCandidates = data.excludeScheduleCandidates || false
      this.excludeAllDays = data.excludeAllDays || false
      this.confirmationForm = data.confirmationForm || initConfirmationForm({ isActive: false })
    }
    this.isLoading = false
  }
  get isDisabledMaxNum() {
    return false
  }

  static exceptionsAsCalendarFormat(exceptions: Exception[]): FullCalendarEvent[] {
    return exceptions.map(e => {
      return {
        id: e.id,
        start: new Date(e.start),
        end: new Date(e.end),
        allDay: e.allDay,
        title: '',
        editable: false,
        extendedProps: {
          source: e.type === 'exclude' ? 'exceptionExclude' : 'exceptionInclude'
        }
      }
    })
  }
  get exceptionsAsCalendarFormat(): FullCalendarEvent[] {
    return AvailabilityModel.exceptionsAsCalendarFormat(this.exceptions)
  }
  get holidaysAsCalendarFormat(): FullCalendarEvent[] {
    return FromHolidays.convertToFullCalendarEvents(this.holidays || [])
  }
  get showDisplayWeekday(): DisplayWeekDay[] {
    return convertToDisplayWeekDay(this.dayOfTheWeekRules)
  }

  // todo: backendのObjectが使えるようになったらそれに変えましょう
  get minEndDate() {
    let current = new Date()
    switch (this.start) {
      case 'one_hour_later':
        current = addHours(current, 1)
        break
      case 'two_hours_later':
        current = addHours(current, 2)
        break
      case 'three_hours_later':
        current = addHours(current, 3)
        break
      case 'four_hours_later':
        current = addHours(current, 4)
        break
      case 'five_hours_later':
        current = addHours(current, 5)
        break
      case 'tomorrow':
        current = startOfDay(addDays(current, 1))
        break
      case 'the_day_after_tomorrow':
        current = startOfDay(addDays(current, 2))
        break
      case 'three_days_later':
        current = startOfDay(addDays(current, 3))
        break
      case 'four_days_later':
        current = startOfDay(addDays(current, 4))
        break
      case 'five_days_later':
        current = startOfDay(addDays(current, 5))
        break
      case 'next_week':
        current = startOfWeek(addWeeks(current, 1), { startWeekDay: 1 })
        break
      case 'the_week_after_next':
        current = startOfWeek(addWeeks(current, 2), { startWeekDay: 1 })
        break
      case 'one_week_later':
        current = addWeeks(current, 1)
        break
      case 'two_weeks_later':
        current = addWeeks(current, 2)
        break
      case 'three_weeks_later':
        current = addWeeks(current, 3)
        break
      case 'four_weeks_later':
        current = addWeeks(current, 4)
        break
      case 'next_month':
        current = startOfMonth(addMonths(current, 1))
        break
    }
    return addDays(current, 1)
  }
  get endDateAsDateFormat(): Date | string {
    const endDate = new Date(this.end)
    return endDate
  }
  updateEndDate() {
    if (this.endDateAsDateFormat === 'Invalid Date') {
      return
    }
    const endDate = this.endDateAsDateFormat as Date
    if (isBefore(endDate, this.minEndDate)) {
      this.end = this.minEndDate.toISOString()
    }
  }
  static getCountriesAndHolidaysRemoved(args: {
    countryCode: FrontSupportCountryCode
    countries: Countries | undefined
    holidays: Holiday[] | undefined
  }): { countries: Countries; holidays: Holiday[] } {
    const countries = (args.countries ?? []).filter((country: Country) => args.countryCode !== country.code)
    const holidays = (args.holidays ?? []).filter((holiday: Holiday) => args.countryCode != holiday.country.code)
    return { countries, holidays }
  }

  getCountriesAndHolidaysRemoved(countryCode: FrontSupportCountryCode): { countries: Countries; holidays: Holiday[] } {
    return AvailabilityModel.getCountriesAndHolidaysRemoved({
      countryCode,
      countries: this.countries,
      holidays: this.holidays
    })
  }
  abstract get isValidToFetchCandidates()
  abstract get parameterForFetchCandidates()
  abstract get parameterForCreate()
}

export class AvailabilityModelForPrivate extends AvailabilityModel {
  attendeeType: AttendeeType
  organizer: AvailabilityCalendarType
  attendees: AvailabilityCalendarType[]
  calendars: Array<AvailabilityAttendee>
  onlineMeeting: IOnlineMeeting
  meetingRooms: AvailabilityCalendarType[]
  maxNumPerDay: number
  constructor(data?: AvailabilitySharingsAvailabilitySharingIdGetResponse) {
    super(data)
    this.type = 'private'
    this.attendeeType = 'and'
    if (!data) {
      //新規作成
      const savedAvailability = new AvailabilityStorage()
      const savedAttrInLocalStorage = savedAvailability.getSavedAttrs()
      const defaultCalendar = getDefaultSelectedCalendar(
        CalendarsModule.writableCalendars,
        savedAttrInLocalStorage.organizer?.accountId && savedAttrInLocalStorage.organizer?.calendarId
          ? {
              accountId: savedAttrInLocalStorage.organizer.accountId,
              calendarId: savedAttrInLocalStorage.organizer.calendarId
            }
          : undefined
      )
      this.organizer = {
        accountId: defaultCalendar.accountId,
        calendarId: defaultCalendar.calendarId
      }
      const selectedCalendar = CalendarsModule.getCalendar({
        accountId: this.organizer.accountId,
        calendarId: this.organizer.calendarId
      })
      const onlineMeeting = getDefaultOnlineMeetingType(
        // @ts-expect-error TS2345
        selectedCalendar,
        savedAttrInLocalStorage.onlineMeeting as IOnlineMeeting
      )
      this.onlineMeeting = onlineMeeting
      this.calendars = [
        {
          accountId: this.organizer.accountId,
          calendarId: this.organizer.calendarId
        }
      ]
      this.duration = savedAttrInLocalStorage.duration || 30
      this.attendees = []
      this.maxNumPerDay = 50
      this.meetingRooms = []
    } else {
      // @ts-expect-error TS2322
      this.calendars = data.calendars.and
      this.attendees = data.attendees || []
      this.organizer = data.organizer
      this.onlineMeeting = (data.onlineMeeting as IOnlineMeeting) || { type: 'none' }
      this.maxNumPerDay = data.maxNumPerDay
      this.meetingRooms = data.meetingRooms || []
    }
  }
  get parameterForCreate() {
    const calendars = {
      and: [],
      or: []
    }
    if (this.attendeeType === 'and') {
      // @ts-expect-error TS2322
      calendars.and = cloneDeep(this.calendars)
    }
    return {
      id: this.id,
      title: this.title,
      organizer: this.organizer,
      attendees: this.attendees,
      candidateTitle: this.candidateTitle,
      candidateDescription: this.candidateDescription,
      duration: this.duration,
      location: this.location,
      meetingRooms: this.meetingRooms,
      description: this.description,
      onlineMeeting: this.onlineMeeting,
      visibility: this.visibility,
      timeBuffer: this.timeBuffer,
      maxNumPerDay: this.isDisabledMaxNum ? MAX_NUM_OF_DAY : this.maxNumPerDay,
      start: this.start,
      end: this.end,
      dayOfTheWeekRules: this.dayOfTheWeekRules,
      exceptions: this.exceptions,
      calendars,
      timeZone: this.timeZone,
      excludeScheduleCandidates: this.excludeScheduleCandidates,
      excludeGroupPollCandidates: this.excludeGroupPollCandidates,
      excludeAllDays: this.excludeAllDays,
      countries: this.countries,
      confirmationForm: this.confirmationForm
    }
  }
  get parameterForDuplicate() {
    return this.parameterForCreate
  }
  get parameterForFetchCandidates(): AvailabilitySharingsPeekCandidatesPostRequest {
    const calendars = {
      and: [],
      or: []
    }
    if (this.attendeeType === 'and') {
      // @ts-expect-error TS2322
      calendars.and = cloneDeep(this.calendars)
    }
    return {
      duration: this.duration,
      start: this.start,
      end: this.end,
      dayOfTheWeekRules: this.dayOfTheWeekRules,
      exceptions: this.exceptions,
      attendees: this.attendees,
      meetingRooms: this.meetingRooms,
      timeBuffer: this.timeBuffer,
      excludeScheduleCandidates: this.excludeScheduleCandidates,
      excludeGroupPollCandidates: this.excludeGroupPollCandidates,
      excludeAllDays: this.excludeAllDays,
      timeZone: this.timeZone,
      maxNumPerDay: this.isDisabledMaxNum ? MAX_NUM_OF_DAY : this.maxNumPerDay,
      calendars,
      countries: this.countries
    }
  }
  get confirmURL(): string {
    return `${window.location.origin}/patterns/availability-sharing/${this.id}/confirm`
  }
  get isDisabledMaxNum() {
    return this.calendars.length > 1
  }
  get usableCalendarsByAccount(): ICalendar[] {
    const currentAccount = CalendarsModule.getAccountWithCalendars.find(
      account => account.accountId === this.organizer.accountId
    )
    return currentAccount ? currentAccount.calendars : []
  }
  updateAndValidation(payload: AvailabilityModelForPrivate): UpdateResult[] {
    const result: UpdateResult[] = []
    const calendar = CalendarModule.getCalendar({
      accountId: this.organizer.accountId,
      calendarId: this.organizer.calendarId
    })
    // @ts-expect-error TS2345
    const onlineMeeting = getDefaultOnlineMeetingType(calendar, this.onlineMeeting)
    if (this.onlineMeeting.type !== onlineMeeting.type) {
      this.onlineMeeting = onlineMeeting
      result.push(UpdateResult.onlineMtgUpdated)
    }
    if (
      payload.meetingRooms?.length > 0 &&
      this.organizer.accountId !== payload.organizer.accountId &&
      this.organizer.calendarId !== payload.organizer.calendarId
    ) {
      this.meetingRooms = []
      result.push(UpdateResult.meetingRoomsRefreshed)
    }
    return result
  }
  get showCalendars(): { accountId: string; calendarId: string }[] {
    return [
      ...(this.calendars?.map(c => {
        return {
          accountId: c.accountId,
          calendarId: c.calendarId
        }
      }) || []),
      ...(this.meetingRooms || [])
    ]
  }
  get isValidToFetchCandidates() {
    if (!this.dayOfTheWeekRules || Object.keys(this.dayOfTheWeekRules).length === 0) {
      return false
    }
    return true
  }
  get editPath(): Location {
    return {
      name: AllRouteNames.PersonalAvailabilitySharingEdit,
      params: {
        // @ts-expect-error TS2322
        availabilitySharingId: this.id
      }
    }
  }
}

export type QuestionnaireIdIntent = { action: 'same' | 'delete' } | { action: 'update'; value: QuestionnaireId }
export class AvailabilityModelForTeam extends AvailabilityModel {
  teamId: string
  memberIds: Array<string>
  onlineMeeting: PrioritizedOnlineMeetings
  notificationSlackChannel?: NotificationSlackChannel
  notifications?: { email: string }[]
  originalQuestionnaireId?: QuestionnaireId
  questionnaireIdIntent: QuestionnaireIdIntent = {
    action: 'same'
  }
  assignPriorityGroups: AssignPriorityGroups
  confirmationPage: { type: 'spir' } | { type: 'external'; url: string; passEventDetails: boolean }
  titleForIndividualEvents: TitleForIndividualEvents = { type: 'default' }
  roomPriorityGroups?: TeamRoomPriorityGroups
  originalRoomPriorityGroups?: TeamRoomPriorityGroups
  inviteCalendar: boolean = true
  bufferSetting: { before: TimeBuffer; after: TimeBuffer; addToCalendar: boolean }

  constructor(teamId: string, data?: TeamTeamIdAvailabilitySharingsPostResponse) {
    super(data)
    this.teamId = teamId
    this.type = 'team'
    if (!data) {
      this.memberIds = []
      this.onlineMeeting = [{ type: 'zoom' }, { type: 'googleMeet' }, { type: 'microsoftTeams' }]
      this.notifications = []
      this.assignPriorityGroups = []
      this.confirmationPage = { type: 'spir' }
      this.bufferSetting = { before: this.timeBuffer, after: this.timeBuffer, addToCalendar: false }
    } else {
      this.onlineMeeting = data.onlineMeeting
      this.notifications = data.notifications
      this.notificationSlackChannel = data.slackWebhook
        ? { id: data.slackWebhook.id, name: data.slackWebhook.channel.name }
        : undefined
      this.originalQuestionnaireId = data.formId
      this.assignPriorityGroups = data.priorityGroups ? convertToAssignPriorityGroups(data.priorityGroups) : []
      //TODO:ASSIGN_LOGIC ResponseのpriorityGroupsをnon nullにしたらifなくす
      if (data.priorityGroups) {
        this.memberIds = [data.priorityGroups.first.first.organizerMemberId].concat(
          data.priorityGroups.first.first.attendeeMemberIds
        )
      } else {
        this.memberIds = []
      }
      this.confirmationPage = data.confirmationPage
      this.titleForIndividualEvents = data.titleForIndividualEvents
      this.roomPriorityGroups = data.roomPriorityGroups
      this.originalRoomPriorityGroups = cloneDeep(data.roomPriorityGroups)
      this.inviteCalendar = data.inviteCalendar
      this.bufferSetting = data.bufferSetting
    }
  }

  static isPriorityGroupsValid(priorityGroups: AssignPriorityGroups): boolean {
    return priorityGroups.length > 0 && priorityGroups[0].length > 0
  }

  static getQuestionnaireId(
    originalQuestionnaireId: QuestionnaireId | undefined,
    questionnaireIdIntent: { action: 'same' | 'delete' } | { action: 'update'; value: QuestionnaireId }
  ) {
    switch (questionnaireIdIntent.action) {
      case 'delete':
        return undefined
      case 'update':
        return questionnaireIdIntent.value
      default:
        return originalQuestionnaireId
    }
  }

  static isValidToFetchCandidates(dayOfTheWeekRules: DayOfTheWeekRules) {
    if (!dayOfTheWeekRules || Object.keys(dayOfTheWeekRules).length === 0) {
      return false
    }
    return true
  }

  static getPriorityGroupsByMemberIds(memberIds: string[]): TeamAvailabilitySharingPriorityGroups {
    const [organizerMemberId, ...attendeeMemberIds] = memberIds
    return {
      first: {
        first: { organizerMemberId, attendeeMemberIds },
        rest: []
      },
      rest: []
    }
  }

  get questionnaireId(): QuestionnaireId | undefined {
    return AvailabilityModelForTeam.getQuestionnaireId(this.originalQuestionnaireId, this.questionnaireIdIntent)
  }

  get parameterForFetchCandidates(): never {
    throw new Error('Unreachable Code. Use parameterForFetchCandidates function in AvailabilityModelForTeam.')
  }

  get parameterForPreviewSlots(): TeamsTeamIdAvailabilitySharingsPreviewSlotsPostRequest {
    const priorityGroups = AvailabilityModelForTeam.getPriorityGroupsByMemberIds(this.memberIds)
    return {
      duration: this.duration,
      start: this.start,
      end: this.end,
      dayOfTheWeekRules: this.dayOfTheWeekRules,
      exceptions: this.exceptions,
      priorityGroups,
      countries: this.countries,
      timeBuffer: this.timeBuffer,
      excludeScheduleCandidates: this.excludeScheduleCandidates,
      excludeGroupPollCandidates: this.excludeGroupPollCandidates,
      excludeAllDays: this.excludeAllDays,
      timeZone: this.timeZone,
      roomPriorityGroups: this.roomPriorityGroups,
      bufferSetting: this.bufferSetting
    }
  }

  get isPriorityGroupsValid(): boolean {
    return AvailabilityModelForTeam.isPriorityGroupsValid(this.assignPriorityGroups)
  }

  get isValidToFetchCandidates() {
    return AvailabilityModelForTeam.isValidToFetchCandidates(this.dayOfTheWeekRules)
  }

  get parameterForCreate(): TeamTeamIdAvailabilitySharingsPostRequest {
    return {
      title: this.title,
      description: this.description,
      candidateTitle: this.candidateTitle,
      candidateDescription: this.candidateDescription,
      location: this.location,
      onlineMeeting: this.onlineMeeting,
      duration: this.duration,
      start: this.start,
      end: this.end,
      dayOfTheWeekRules: this.dayOfTheWeekRules,
      exceptions: this.exceptions,
      countries: this.countries,
      // @ts-expect-error TS2322
      priorityGroups: this.isPriorityGroupsValid
        ? convertToTeamAvailabilitySharingPriorityGroups(this.assignPriorityGroups)
        : undefined,
      timeBuffer: this.timeBuffer,
      excludeScheduleCandidates: this.excludeScheduleCandidates,
      excludeGroupPollCandidates: this.excludeGroupPollCandidates,
      excludeAllDays: this.excludeAllDays,
      visibility: this.visibility,
      timeZone: this.timeZone,
      notifications: this.notifications,
      // @ts-expect-error TS2322
      confirmationForm: this.confirmationForm,
      slackWebhookId: this.notificationSlackChannel ? this.notificationSlackChannel.id : undefined,
      formId: this.questionnaireIdIntent.action === 'update' ? this.questionnaireIdIntent.value : undefined,
      confirmationPage: this.confirmationPage,
      titleForIndividualEvents: this.titleForIndividualEvents,
      roomPriorityGroups: this.roomPriorityGroups,
      inviteCalendar: this.inviteCalendar,
      bufferSetting: this.bufferSetting
    }
  }
  get parameterForDuplicate(): TeamTeamIdAvailabilitySharingsPostRequest {
    return {
      ...this.parameterForCreate,
      formId: this.originalQuestionnaireId
    }
  }

  get formIdParam(): TeamsTeamIdAvailabilitySharingsAvailabilitySharingIdPatchRequest['formId'] {
    switch (this.questionnaireIdIntent.action) {
      case 'delete':
        return { action: 'delete' }
      case 'update':
        return this.questionnaireIdIntent
      default:
        return undefined
    }
  }

  get roomPriorityGroupsIntent(): TeamsTeamIdAvailabilitySharingsAvailabilitySharingIdPatchRequest['roomPriorityGroups'] {
    if (this.originalRoomPriorityGroups === undefined && this.roomPriorityGroups === undefined) {
      return undefined
    } else if (this.originalRoomPriorityGroups === undefined && this.roomPriorityGroups !== undefined) {
      return { action: 'update', value: this.roomPriorityGroups }
    } else if (this.originalRoomPriorityGroups !== undefined && this.roomPriorityGroups === undefined) {
      return { action: 'delete' }
    } else if (this.originalRoomPriorityGroups !== undefined && this.roomPriorityGroups !== undefined) {
      return deepEqual(this.originalRoomPriorityGroups, this.roomPriorityGroups)
        ? undefined
        : { action: 'update', value: this.roomPriorityGroups }
    }
  }

  get parameterForEdit(): TeamsTeamIdAvailabilitySharingsAvailabilitySharingIdPatchRequest {
    const createParams = this.parameterForCreate
    return {
      ...createParams,
      slackWebhookId: this.notificationSlackChannel ? this.notificationSlackChannel.id : '',
      formId: this.formIdParam,
      roomPriorityGroups: this.roomPriorityGroupsIntent
    }
  }

  get showMemberCalendars(): Array<string> {
    return [...this.memberIds].filter(m => m)
  }
}

export class AvailabilitySummaryModelForTeam {
  type = 'team'
  id: string
  teamId: string
  title: string
  candidateTitle: string
  duration: Duration
  timeBuffer: TimeBuffer
  isPublished: boolean
  dayOfTheWeekRules: DayOfTheWeekRules
  timeZone: string
  assignPriorityGroups: AssignPriorityGroups
  isLoading: boolean

  constructor(args: {
    id: string
    teamId: string
    title: string
    candidateTitle: string
    duration: Duration
    timeBuffer: TimeBuffer
    isPublished: boolean
    dayOfTheWeekRules: DayOfTheWeekRules
    timeZone: string
    assignPriorityGroups: AssignPriorityGroups
  }) {
    this.id = args.id
    this.teamId = args.teamId
    this.title = args.title
    this.candidateTitle = args.candidateTitle
    this.duration = args.duration
    this.timeBuffer = args.timeBuffer
    this.isPublished = args.isPublished
    this.dayOfTheWeekRules = args.dayOfTheWeekRules
    this.timeZone = args.timeZone
    this.assignPriorityGroups = args.assignPriorityGroups
    this.isLoading = false
  }

  static createFrom(teamId: string, data: TeamAvailabilitySharingListResponseItem) {
    return new AvailabilitySummaryModelForTeam({
      id: data.id,
      teamId,
      title: data.title,
      candidateTitle: data.candidateTitle,
      duration: data.duration,
      timeBuffer: data.timeBuffer,
      isPublished: data.isPublished,
      dayOfTheWeekRules: data.dayOfTheWeekRules,
      timeZone: data.timeZone,
      assignPriorityGroups: convertToAssignPriorityGroups(data.priorityGroups)
    })
  }

  static createFromAvailabilityModelForTeam(data: AvailabilityModelForTeam) {
    return new AvailabilitySummaryModelForTeam({
      id: data.id ?? '',
      teamId: data.teamId,
      title: data.title,
      candidateTitle: data.candidateTitle,
      duration: data.duration,
      timeBuffer: data.timeBuffer,
      isPublished: data.isPublished,
      dayOfTheWeekRules: data.dayOfTheWeekRules,
      timeZone: data.timeZone,
      assignPriorityGroups: data.assignPriorityGroups
    })
  }

  get confirmURL(): string {
    return `${window.location.origin}/t/${this.teamId}/as/${this.id}/confirm`
  }
  get editPath(): Location {
    return {
      name: AllRouteNames.TeamAvailabilitySharingEdit,
      params: {
        id: this.teamId,
        availabilitySharingId: this.id
      }
    }
  }

  get teamInfo(): TeamDetailModel {
    // @ts-expect-error TS2322
    return TeamRecordModule.teamByTeamId(this.teamId)?.team
  }
  get allTeamMembers(): MemberResponse[] {
    return this.teamInfo?.members || []
  }
  get allActiveTeamMembers(): MemberResponse[] {
    return this.allTeamMembers.filter(m => m.status === 'active')
  }

  get allAttendingMembers(): MemberResponse[] {
    const allAttendingMemberIds = this.assignPriorityGroups.flatMap(groups => groups.flatMap(g => g.memberIds))
    return allAttendingMemberIds
      .map(id => {
        const memberInfo = this.allActiveTeamMembers.find(am => am.id === id)
        return memberInfo
      })
      .filter(m => !!m)
  }

  get showDisplayWeekday(): DisplayWeekDay[] {
    return convertToDisplayWeekDay(this.dayOfTheWeekRules)
  }
}
