import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=3ad4483b&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=ts&"
export * from "./Index.vue?vue&type=script&lang=ts&"
import style0 from "./Index.vue?vue&type=style&index=0&id=3ad4483b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26._318391594ffc77c137d7717a40590496/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ad4483b",
  null
  
)

export default component.exports