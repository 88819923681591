import UserModule from '@/store/modules/user'
import { NavigationGuard, Route } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'
import { AllRouteNames, RouteNames } from '.'

function sanitizeQuery(query: Dictionary<string | (string | null)[]>): Dictionary<string | string[]> {
  const result: Dictionary<string | string[]> = {}

  for (const key of Object.keys(query)) {
    const value = query[key]
    if (Array.isArray(value)) {
      // null値を除外/置換する
      result[key] = value.filter(item => item !== null) as string[]
    } else {
      result[key] = value
    }
  }

  return result
}
type GuardConfirmInfo =
  | { type: 'next' }
  | {
      type: 'redirect'
      redirect: { name: RouteNames; params: { [key: string]: string }; query: Dictionary<string | string[]> }
    }

// Personal Schedule Guard
const guardScheduleConfirm = (isSignIn: boolean, to: Route, isActive: boolean): GuardConfirmInfo => {
  // TODO: 本番open時に削除 上の引数も削除
  if (!isActive) return { type: 'next' }
  return isSignIn
    ? { type: 'next' }
    : {
        type: 'redirect',
        redirect: {
          name: AllRouteNames.ConfirmScheduleGuest,
          params: { id: to.params.id },
          query: sanitizeQuery(to.query)
        }
      }
}

const guardScheduleConfirmGuest = (isSignIn: boolean, to: Route, isActive: boolean): GuardConfirmInfo => {
  // TODO: 本番open時に削除 上の引数も削除
  if (!isActive)
    return {
      type: 'redirect',
      redirect: {
        name: AllRouteNames.ConfirmSchedule,
        params: { id: to.params.id },
        query: sanitizeQuery(to.query)
      }
    }
  return isSignIn
    ? {
        type: 'redirect',
        redirect: {
          name: AllRouteNames.ConfirmSchedule,
          params: { id: to.params.id },
          query: sanitizeQuery(to.query)
        }
      }
    : { type: 'next' }
}

// Personal AvailabilitySharing Guard
const guardAvailabilitySharingConfirm = (isSignIn: boolean, to: Route): GuardConfirmInfo => {
  return isSignIn
    ? { type: 'next' }
    : {
        type: 'redirect',
        redirect: {
          name: AllRouteNames.AvailabilityConfirmGuest,
          params: { availabilitySharingId: to.params.id },
          query: sanitizeQuery(to.query)
        }
      }
}

const guardAvailabilitySharingConfirmGuest = (isSignIn: boolean, to: Route): GuardConfirmInfo => {
  return isSignIn
    ? {
        type: 'redirect',
        redirect: {
          name: AllRouteNames.AvailabilityConfirm,
          params: { id: to.params.availabilitySharingId },
          query: sanitizeQuery(to.query)
        }
      }
    : { type: 'next' }
}

// Team Schedule Guard
const guardTeamScheduleConfirm = (isSignIn: boolean, to: Route, isActive: boolean): GuardConfirmInfo => {
  // TODO: 本番open時に削除 上の引数も削除
  if (!isActive) return { type: 'next' }
  return isSignIn
    ? { type: 'next' }
    : {
        type: 'redirect',
        redirect: {
          name: AllRouteNames.TeamScheduleConfirmGuest,
          params: { id: to.params.id, scheduleId: to.params.scheduleId },
          query: sanitizeQuery(to.query)
        }
      }
}

const guardTeamScheduleConfirmGuest = (isSignIn: boolean, to: Route, isActive: boolean): GuardConfirmInfo => {
  // TODO: 本番open時に削除 上の引数も削除
  if (!isActive)
    return {
      type: 'redirect',
      redirect: {
        name: AllRouteNames.TeamScheduleConfirm,
        params: { id: to.params.id, scheduleId: to.params.scheduleId },
        query: sanitizeQuery(to.query)
      }
    }
  return isSignIn
    ? {
        type: 'redirect',
        redirect: {
          name: AllRouteNames.TeamScheduleConfirm,
          params: { id: to.params.id, scheduleId: to.params.scheduleId },
          query: sanitizeQuery(to.query)
        }
      }
    : { type: 'next' }
}

// Team AvailabilitySharing Guard
const guardTeamAvailabilitySharingConfirm = (isSignIn: boolean, to: Route, isActive: boolean): GuardConfirmInfo => {
  // TODO: 本番open時に削除 上の引数も削除
  if (!isActive) return { type: 'next' }
  return isSignIn
    ? { type: 'next' }
    : {
        type: 'redirect',
        redirect: {
          name: AllRouteNames.TeamAvailabilitySharingConfirmGuest,
          params: { availabilitySharingId: to.params.id, teamId: to.params.teamId },
          query: sanitizeQuery(to.query)
        }
      }
}

const guardTeamAvailabilitySharingConfirmGuest = (
  isSignIn: boolean,
  to: Route,
  isActive: boolean
): GuardConfirmInfo => {
  // TODO: 本番open時に削除 上の引数も削除
  if (!isActive)
    return {
      type: 'redirect',
      redirect: {
        name: AllRouteNames.TeamAvailabilitySharingConfirm,
        params: { id: to.params.availabilitySharingId, teamId: to.params.teamId },
        query: sanitizeQuery(to.query)
      }
    }
  return isSignIn
    ? {
        type: 'redirect',
        redirect: {
          name: AllRouteNames.TeamAvailabilitySharingConfirm,
          params: { id: to.params.availabilitySharingId, teamId: to.params.teamId },
          query: sanitizeQuery(to.query)
        }
      }
    : { type: 'next' }
}

// Personal GroupPoll Guard
const guardPersonalGroupPollVote = (isSignIn: boolean, to: Route, isActive: boolean): GuardConfirmInfo => {
  if (!isActive) return { type: 'next' }
  return isSignIn
    ? { type: 'next' }
    : {
        type: 'redirect',
        redirect: {
          name: AllRouteNames.VotePollGuest,
          params: { id: to.params.id },
          query: sanitizeQuery(to.query)
        }
      }
}

const guardPersonalGroupPollVoteGuest = (isSignIn: boolean, to: Route, isActive: boolean): GuardConfirmInfo => {
  if (!isActive)
    return {
      type: 'redirect',
      redirect: {
        name: AllRouteNames.VotePoll,
        params: { id: to.params.id },
        query: sanitizeQuery(to.query)
      }
    }
  return isSignIn
    ? {
        type: 'redirect',
        redirect: {
          name: AllRouteNames.VotePoll,
          params: { id: to.params.id },
          query: sanitizeQuery(to.query)
        }
      }
    : { type: 'next' }
}

function getSpecifiedGuard({
  isActive = false,
  getGuardInfo
}: {
  isActive: boolean
  getGuardInfo: (isSignIn: boolean, to: Route, isActive: boolean) => GuardConfirmInfo
}): NavigationGuard {
  return (to, from, next) => {
    const isSignIn = UserModule.isSignIn
    // @ts-expect-error TS2345
    const result = getGuardInfo(isSignIn, to, isActive)
    if (result.type === 'next') next()
    else next(result.redirect)
  }
}

// Personal Schedule Confirmation
export const getScheduleGuardInfo = (isSignIn: boolean, to: Route, isActive: boolean): GuardConfirmInfo => {
  switch (to.name) {
    case AllRouteNames.ConfirmSchedule:
      return guardScheduleConfirm(isSignIn, to, isActive)
    case AllRouteNames.ConfirmScheduleGuest:
      return guardScheduleConfirmGuest(isSignIn, to, isActive)
    default:
      return { type: 'next' }
  }
}

export const getScheduleGuard = ({ isActive = false }: { isActive: boolean }): NavigationGuard => {
  return getSpecifiedGuard({ isActive, getGuardInfo: getScheduleGuardInfo })
}

// Personal AvailabilitySharing Confirmation
export const getConfirmGuardInfo = (isSignIn: boolean, to: Route): GuardConfirmInfo => {
  switch (to.name) {
    case AllRouteNames.AvailabilityConfirm:
      return guardAvailabilitySharingConfirm(isSignIn, to)
    case AllRouteNames.AvailabilityConfirmGuest:
      return guardAvailabilitySharingConfirmGuest(isSignIn, to)
    default:
      return { type: 'next' }
  }
}

const guardConfirm: NavigationGuard = (to, from, next) => {
  const isSignIn = UserModule.isSignIn
  // @ts-expect-error TS2345
  const result = getConfirmGuardInfo(isSignIn, to)
  if (result.type === 'next') next()
  else next(result.redirect)
}

// Team Schedule Confirmation
export const getTeamScheduleGuardInfo = (isSignIn: boolean, to: Route, isActive: boolean): GuardConfirmInfo => {
  switch (to.name) {
    case AllRouteNames.TeamScheduleConfirm:
      return guardTeamScheduleConfirm(isSignIn, to, isActive)
    case AllRouteNames.TeamScheduleConfirmGuest:
      return guardTeamScheduleConfirmGuest(isSignIn, to, isActive)
    default:
      return { type: 'next' }
  }
}

export const getTeamScheduleGuard = ({ isActive = false }: { isActive: boolean }): NavigationGuard => {
  return getSpecifiedGuard({ isActive, getGuardInfo: getTeamScheduleGuardInfo })
}

// Personal Team AvailabilitySharing Confirmation
// 本番Open時に削除 isActive
export const getTeamAvailabilitySharingGuardInfo = (
  isSignIn: boolean,
  to: Route,
  isActive: boolean
): GuardConfirmInfo => {
  switch (to.name) {
    case AllRouteNames.TeamAvailabilitySharingConfirm:
      return guardTeamAvailabilitySharingConfirm(isSignIn, to, isActive)
    case AllRouteNames.TeamAvailabilitySharingConfirmGuest:
      return guardTeamAvailabilitySharingConfirmGuest(isSignIn, to, isActive)
    default:
      return { type: 'next' }
  }
}

const getTeamAvailabilitySharingGuard = ({ isActive = false }: { isActive: boolean }): NavigationGuard => {
  return getSpecifiedGuard({ isActive, getGuardInfo: getTeamAvailabilitySharingGuardInfo })
}

// Personal GuardPoll Voting
export const getPersonalGroupPollVoteGuardInfo = (
  isSignIn: boolean,
  to: Route,
  isActive: boolean
): GuardConfirmInfo => {
  switch (to.name) {
    case AllRouteNames.VotePoll:
      return guardPersonalGroupPollVote(isSignIn, to, isActive)
    case AllRouteNames.VotePollGuest:
      return guardPersonalGroupPollVoteGuest(isSignIn, to, isActive)
    default:
      return { type: 'next' }
  }
}

const getPersonalGroupPollGuard = ({ isActive = false }: { isActive: boolean }): NavigationGuard => {
  return getSpecifiedGuard({ isActive, getGuardInfo: getPersonalGroupPollVoteGuardInfo })
}

// router/index.ts内で使われるものをまとめてexport
export const GuardsInRouter = {
  getScheduleGuard,
  getTeamScheduleGuard,
  getTeamAvailabilitySharingGuard,
  getPersonalGroupPollGuard,
  guardConfirm
}
