import { SubscriptionStatus } from '@/models'
import { SpirOperatedTeamSubscription, StripeTeamSubscription, TeamSubscriptionState } from '@/models/subscriptionPlan'
import { SpirOperatedSubscriptionDto, StripeSubscriptionDto, SubscriptionStateDto } from '@spirinc/contracts'
import { parseISO } from 'date-fns'

function convertToTeamSubscription(
  subscription: StripeSubscriptionDto | SpirOperatedSubscriptionDto
): StripeTeamSubscription | SpirOperatedTeamSubscription {
  const base = {
    id: subscription.id,
    planId: subscription.planId,
    endDate: parseISO(subscription.endDate),
    updatedAt: parseISO(subscription.updatedAt),
    createdAt: parseISO(subscription.createdAt)
  }
  if (subscription.type === 'stripe') {
    return {
      ...base,
      type: subscription.type,
      priceId: subscription.priceId
    }
  } else {
    return { ...base, type: subscription.type }
  }
}

export const FromSubscriptionStateDto = {
  convertToTeamSubscriptionState(dto: SubscriptionStateDto): TeamSubscriptionState {
    const status = dto.status
    const subscription = dto.subscription
    const teamSubscription = convertToTeamSubscription(subscription)
    switch (status) {
      case SubscriptionStatus.canceled:
        return {
          status: dto.status,
          subscription: teamSubscription,
          reason: dto.reason,
          active: dto.active
        }
      case SubscriptionStatus.trialWithoutSetup:
        if (teamSubscription.type === 'spirOperated') {
          return {
            status: dto.status,
            subscription: teamSubscription,
            active: dto.active
          }
        } else {
          throw new Error(`Must not reach here because trialWithoutSetup does not expect ${teamSubscription.type} type`)
        }
      case SubscriptionStatus.trialing:
      case SubscriptionStatus.active:
      case SubscriptionStatus.pastDue:
      case SubscriptionStatus.canceling:
      case SubscriptionStatus.incomplete:
        if (teamSubscription.type === 'stripe') {
          return {
            status: dto.status,
            subscription: teamSubscription,
            active: dto.active
          }
        } else {
          throw new Error(`Must not reach here because ${teamSubscription.type} type is not expected.`)
        }
      default:
        throw new Error(`Must not reach here because of unexpected status: ${status}`)
    }
  }
}
