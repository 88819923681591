import { AssignPriorityGroups, AttendeeGroup } from '@/models'
import type {
  AvailabilityAttendee,
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdOverviewGetResponse,
  TeamAvailabilitySharingAssignGroup,
  TeamAvailabilitySharingPriorityGroup,
  TeamAvailabilitySharingPriorityGroups
} from '@spirinc/contracts'
import { nanoid } from 'nanoid'

function convertToAssignAttendeeGroup(assignGroup: TeamAvailabilitySharingAssignGroup): AttendeeGroup {
  return { id: nanoid(), memberIds: [assignGroup.organizerMemberId, ...assignGroup.attendeeMemberIds] }
}

function convertToAssignAttendeeGroups(priorityGroup: TeamAvailabilitySharingPriorityGroup): AttendeeGroup[] {
  return [convertToAssignAttendeeGroup(priorityGroup.first)].concat(
    priorityGroup.rest.map(convertToAssignAttendeeGroup)
  )
}

export function convertToAssignPriorityGroups(
  priorityGroups: TeamAvailabilitySharingPriorityGroups
): AssignPriorityGroups {
  return [convertToAssignAttendeeGroups(priorityGroups.first)].concat(
    priorityGroups.rest.map(convertToAssignAttendeeGroups)
  )
}

function convertToTeamAvailabilitySharingAssignGroup(assignGroup: AttendeeGroup): TeamAvailabilitySharingAssignGroup {
  const [organizerMemberId, ...attendeeMemberIds] = assignGroup.memberIds
  return { organizerMemberId, attendeeMemberIds }
}

function convertToTeamAvailabilitySharingPriorityGroup(
  priorityGroup: AttendeeGroup[]
): TeamAvailabilitySharingPriorityGroup {
  const [first, ...rest] = priorityGroup
  return {
    first: convertToTeamAvailabilitySharingAssignGroup(first),
    rest: rest.map(convertToTeamAvailabilitySharingAssignGroup)
  }
}

export function convertToTeamAvailabilitySharingPriorityGroups(
  assignPriorityGroups: AssignPriorityGroups
): TeamAvailabilitySharingPriorityGroups {
  const [first, ...rest] = assignPriorityGroups
  return {
    first: convertToTeamAvailabilitySharingPriorityGroup(first),
    rest: rest.map(convertToTeamAvailabilitySharingPriorityGroup)
  }
}

function findMemberFromPotentialAttendingMembers(
  potentialAttendingMembers: PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdOverviewGetResponse['potentialAttendingMembers'],
  memberId: string
): AvailabilityAttendee | undefined {
  const member = potentialAttendingMembers.find(m => m.memberId === memberId)
  return member
    ? {
        accountId: member.accountId,
        calendarId: member.calendarId,
        userId: member.userId,
        fullName: member.fullName,
        email: member.email,
        photoURL: member.photoURL
      }
    : undefined
}

type FindOrganizerAndAttendees = Pick<
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdOverviewGetResponse,
  'potentialAttendingMembers' | 'priorityGroups'
>
export function findOrganizerAndAttendees({ priorityGroups, potentialAttendingMembers }: FindOrganizerAndAttendees): {
  organizer: AvailabilityAttendee | undefined
  attendees: AvailabilityAttendee[]
} {
  const priorityGroupsHasMultipleGroups = priorityGroups.rest.length !== 0
  const firstPriorityGroupHasMultipleAssignGroups = priorityGroups.first.rest.length !== 0
  if (priorityGroupsHasMultipleGroups || firstPriorityGroupHasMultipleAssignGroups) {
    return { organizer: undefined, attendees: [] }
  } else {
    const organizer = findMemberFromPotentialAttendingMembers(
      potentialAttendingMembers,
      priorityGroups.first.first.organizerMemberId
    )
    const attendees = priorityGroups.first.first.attendeeMemberIds
      .map(mId => findMemberFromPotentialAttendingMembers(potentialAttendingMembers, mId))
      .filter(m => m !== undefined)
    return { organizer, attendees }
  }
}
